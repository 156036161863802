type TawkUserParams = {
  hash: string
  userId: string
  name: string
  email?: string
  truck: string
}

type TawkGlobal = {
  setAttributes: (
    params: TawkUserParams,
    callback: (error: unknown) => void
  ) => void
  login: (params: TawkUserParams, callback: (error: unknown) => void) => void
  logout: (callback: (error: unknown) => void) => void
  visitor: Partial<TawkUserParams>
}

type ChatLoginArgs = {
  user: {
    id: number
    name: string
    email: string
    tawkHash: string
    restaurant: {
      name: string
    }
  }
}

export const addChatCallback = (callback: () => void) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const global = window as any
  if (typeof global === "undefined") {
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  global.Tawk_API_Callbacks = global.Tawk_API_Callbacks || []

  const { Tawk_API_Callbacks } = global

  if (global?.Tawk_Window?.app) {
    callback()
    return
  }

  Tawk_API_Callbacks.push(callback)
}

export function chatLogin({ user }: ChatLoginArgs) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const global = window as any

  if (typeof global === "undefined") {
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  global.Tawk_API = global.Tawk_API || {}

  addChatCallback(() => {
    const Tawk = global.Tawk_API as TawkGlobal

    if (!user.tawkHash) return

    const params = {
      hash: user.tawkHash,
      userId: user.id.toString(),
      name: user.name,
      truck: user.restaurant.name,
      email: user.email,
      // // set custom properties as well
      "user-email": user.email
    }

    // buggy for some reason
    // Tawk.visitor = params
    Tawk.setAttributes(params, (error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  })
}

export function chatLogout() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const global = window as any

  if (typeof global === "undefined") {
    return
  }

  global.Tawk_API = global.Tawk_API || {}

  addChatCallback(() => {
    const Tawk = global.Tawk_API as TawkGlobal

    if (!Tawk.visitor || Tawk.visitor === {}) return

    Tawk.visitor = {}
    Tawk.setAttributes(
      {
        hash: "",
        userId: "",
        email: "",
        name: "",
        truck: ""
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    )
  })
}

/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import Rollbar from "common/utils/Rollbar"

Rollbar.init()

// Import all the third party stuff
import React from "react"
import "sanitize.css/sanitize.css"
import "common/i18n"

// eslint-disable-next-line
import formActionSaga from "common/pkg/redux-form-saga"
import confirmDialog from "trucks/utils/confirmDialog"
import requestValue from "trucks/utils/requestValue"

// Import root app
import App from "trucks/containers/App"

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions, import/no-webpack-loader-syntax */
import "!file-loader?name=[name]!./apple-app-site-association"
import "!file-loader?name=[name].[ext]!./trucks.manifest"
import "!file-loader?name=[name].[ext]!images/favicon.ico"
import "!file-loader?name=[name].[ext]!images/favicon-48.png"
import "!file-loader?name=[name].[ext]!images/favicon-96.png"
import "!file-loader?name=[name].[ext]!images/favicon-144.png"
import "!file-loader?name=[name].[ext]!images/favicon-192.png"
import "!file-loader?name=[name].[ext]!images/logo512.png"
/* eslint-enable import/no-unresolved, import/extensions, import/no-webpack-loader-syntax */

// Import CSS reset and Global Styles
import theme, { muiTheme, jss } from "./theme"

import ConnectedApp from "common/ConnectedApp"
import DashboardTypeContext from "common/DashboardTypeContext"
import logEventConfig from "trucks/utils/logEventConfig"
import configureStore from "./configureStore"

export default function TrucksApp() {
  return (
    <ConnectedApp
      authTokenName={"X-Employee-Token"}
      authEmailName={"X-Employee-Email"}
      jss={jss}
      theme={theme}
      muiTheme={muiTheme}
      logEventConfig={logEventConfig}
      configureStore={configureStore}
      confirmDialog={confirmDialog}
      requestValue={requestValue}
    >
      <DashboardTypeContext.Provider value={"trucks"}>
        <App />
      </DashboardTypeContext.Provider>
    </ConnectedApp>
  )
}

/**
 *
 * Navbar
 *
 */

import React, { useEffect } from "react"
import styled from "styled-components"
import { createStructuredSelector } from "reselect"
import { compose } from "redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import injectReducer from "trucks/utils/injectReducer"
import makeSelectNavbar from "./selectors"
import reducer from "./reducer"
import { logout } from "trucks/auth/actions"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import MenuIcon from "@material-ui/icons/Menu"
import Hidden from "@material-ui/core/Hidden"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import AccountCircle from "@material-ui/icons/AccountCircle"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Sidebar from "trucks/components/Sidebar"
import { closeSidebar, openSidebar } from "./actions"
import styles from "common/components/Navbar/navbarStyles"
import withUser from "trucks/queries/withUser"
import { withApollo } from "@apollo/client/react/hoc"
import TruckAppButton from "./TruckAppButton"
import appUrl from "./TruckAppButton/appUrl"
import matchMedia from "matchmediaquery"
import HelpIcon from "@material-ui/icons/HelpOutline"
import { addChatCallback } from "trucks/utils/chatBox"

const OpenChatButton = () => {
  const [render, setRender] = React.useState(false)
  const isMobile = matchMedia("(max-width: 600px)").matches

  const global = window

  useEffect(() => {
    addChatCallback(() => {
      setRender(true)
    })
  }, [])

  if (!isMobile) return null
  if (!render) return null

  const openChat = () => {
    global.Tawk_API.maximize()
  }

  return (
    <Button
      variant="text"
      color="inherit"
      onClick={() => openChat()}
      style={{ minWidth: 16 }}
    >
      <HelpIcon />
    </Button>
  )
}

const ToolbarItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`

const MenuWrapper = styled.div`
  margin-left: auto;
  white-space: nowrap;
`

const NoWrap = styled.div`
  white-space: nowrap;
`

const UserIcon = styled(AccountCircle)``

export const NavbarContext = React.createContext({
  setHeaderComponent: null
})

class Navbar extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      anchorEl: null,
      headerComponent: null,
      context: {
        setHeaderComponent: this.setHeaderComponent
      }
    }
  }

  logout = () => {
    const { client } = this.props
    this.handleRequestClose()
    this.props.dispatch(logout())
    client.resetStore()
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleRequestClose = () => {
    this.setState({ anchorEl: null })
  }

  handleDrawerToggle = () => {
    const { navbar } = this.props
    if (navbar.state.mobileOpen) {
      this.props.dispatch(closeSidebar())
    } else {
      this.props.dispatch(openSidebar())
    }
  }

  openDrawer = () => {
    this.props.dispatch(openSidebar())
  }

  closeDrawer = () => {
    this.props.dispatch(closeSidebar())
  }

  setHeaderComponent = (headerComponent) => {
    this.setState({
      headerComponent
    })
  }

  render() {
    const { classes, user, navbar } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    const drawer = <Sidebar />
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    return (
      <NavbarContext.Provider value={this.state.context}>
        <div className={classes.appFrame}>
          <AppBar
            className={classes.appBar}
            classes={{ root: classes.appBarRoot }}
          >
            <Toolbar>
              <ToolbarItemsWrapper>
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.navIconHide}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <NoWrap>{this.state.headerComponent}</NoWrap>
                {user ? (
                  <MenuWrapper>
                    <OpenChatButton />
                    <TruckAppButton />
                    {!appUrl() && (
                      <Button
                        variant="text"
                        onClick={this.handleMenu}
                        color="inherit"
                        data-test="user-selector"
                      >
                        <span className={classes.buttonUserName}>
                          {user.name}
                        </span>{" "}
                        <UserIcon />
                      </Button>
                    )}
                    <Menu
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}
                      getContentAnchorEl={null}
                      open={open}
                      onClose={this.handleRequestClose}
                    >
                      <MenuItem className={classes.menuUserName}>
                        {user.name}
                      </MenuItem>
                      <MenuItem onClick={this.logout}>Logout</MenuItem>
                    </Menu>
                  </MenuWrapper>
                ) : (
                  <MenuWrapper>
                    <TruckAppButton />
                  </MenuWrapper>
                )}
              </ToolbarItemsWrapper>
            </Toolbar>
          </AppBar>
          <Hidden mdUp>
            <SwipeableDrawer
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
              anchor="left"
              open={navbar.state.mobileOpen}
              classes={{
                paper: classes.drawerPaper
              }}
              onOpen={this.openDrawer}
              onClose={this.closeDrawer}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </SwipeableDrawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              variant="permanent"
              open
              classes={{
                docked: classes.drawerDocked,
                paper: classes.drawerPaper
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <main id="page-main" className={classes.content}>
            {this.props.children}
          </main>
        </div>
      </NavbarContext.Provider>
    )
  }
}

Navbar.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  navbar: PropTypes.object,
  children: PropTypes.any,
  dispatch: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
  navbar: makeSelectNavbar()
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({
  key: "navbar",
  reducer
})

export default compose(
  withReducer,
  withConnect,
  withUser,
  withApollo,
  withStyles(styles, { withTheme: true })
)(Navbar)

/**
 *
 * Navbar
 *
 */

import React from "react"
import { createStructuredSelector } from "reselect"
import { compose } from "redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"
import withWidth from "@material-ui/core/withWidth"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import bftLogo from "common/images/bft-logo-black-medium.png"
import { closeSidebar } from "trucks/components/Navbar/actions"
import _ from "lodash"
import withUser from "trucks/queries/withUser"
import { logout } from "common/auth/actions"
import { withApollo } from "@apollo/client/react/hoc"
import SIDEBAR_ITEMS from "./sidebarItems"
import { makeSelectLocation } from "common/selectors"
import ForwardNavLink from "common/components/ForwardNavLink"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Badge from "@material-ui/core/Badge"

const Header = styled.div`
  min-height: 64px;
  ${({ theme }) => theme.media.md`min-height: 56px`};
`
const HeaderImg = styled.img`
  height: 56px;
  width: 240px;
`

const StyledListItem = styled(ListItem)`
  ${({ current }) =>
    current === "true" && "background-color: rgba(0, 0, 0, 0.12) !important;"};
`

class Sidebar extends React.Component {
  isActive = (page, path) => !!_.find(page.matches, (match) => match.test(path))

  makeGoToLink = (page) => (e) => {
    const { dispatch, client } = this.props
    if (page.action === "logout") {
      e.preventDefault()
      client.resetStore()
      dispatch(logout())
    } else if (page.action === "goBackToApp") {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "back"
        })
      )
    } else if (page.link) {
      // do nothing
    } else {
      throw new Error("Bad Sidebar Item")
    }
    dispatch(closeSidebar())
  }

  getStyledListItem(page, location, i) {
    const link = _.isFunction(page.link) ? page.link() : page.link
    const isExternal = link.includes("://") || link.includes("https://")

    const { width } = this.props
    const dense = ["xs", "sm"].includes(width)
    return (
      <StyledListItem
        button
        onClick={this.makeGoToLink(page)}
        current={`${this.isActive(page, location.pathname)}`}
        component={isExternal ? "a" : ForwardNavLink}
        to={isExternal ? undefined : link}
        href={isExternal ? link : undefined}
        target={isExternal ? "_target" : undefined}
        key={i}
        dense={dense}
        divider
      >
        <ListItemAvatar>
          {page.badgeContent ? (
            page.badgeContent((content) => (
              <Badge
                badgeContent={content ?? null}
                color="primary"
                overlap="circle"
              >
                <Avatar>{page.icon}</Avatar>
              </Badge>
            ))
          ) : (
            <Avatar>{page.icon}</Avatar>
          )}
        </ListItemAvatar>
        <ListItemText primary={page.name} />
      </StyledListItem>
    )
  }

  render() {
    const { user, location } = this.props
    const header = (
      <Header>
        <HeaderImg src={bftLogo} alt="" />
      </Header>
    )
    return (
      <div>
        {header}
        <Divider />
        <List>
          {SIDEBAR_ITEMS.filter(({ noSession }) =>
            user ? !noSession : noSession
          )
            .filter(({ showIf }) => (showIf ? showIf({ user }) : true))
            .map((page, i) => this.getStyledListItem(page, location, i))}
        </List>
      </div>
    )
  }
}

Sidebar.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  dispatch: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation()
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect, withUser, withWidth(), withApollo)(Sidebar)
